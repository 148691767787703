import React from 'react';
import "./SVG.css"

const SvgGithub = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40px"
    height="40px"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M21 5.958c.009.607-.067 1.368-.134 1.923a4.163 4.163 0 0 1-.1.544C21.622 10.01 22 11.917 22 14c0 2.468-1.187 4.501-3.036 5.887C17.132 21.26 14.66 22 12 22c-2.66 0-5.132-.74-6.964-2.113C3.187 18.501 2 16.468 2 14c0-2.083.377-3.99 1.235-5.575a4.166 4.166 0 0 1-.1-.544C3.066 7.326 2.99 6.565 3 5.958c.01-.683.1-1.366.199-2.044.046-.314.118-.609.459-.795.348-.19.714-.12 1.075-.017 1.218.345 2.36.83 3.434 1.41C9.3 4.173 10.578 4 12 4c1.422 0 2.7.173 3.832.513a16.802 16.802 0 0 1 3.434-1.41c.361-.103.728-.174 1.075.016.34.186.413.481.46.795.098.678.188 1.361.198 2.044ZM20 14c0-1.687-.388-4-2.5-4-.952 0-1.853.25-2.753.5-.899.25-1.797.5-2.747.5s-1.848-.25-2.747-.5c-.9-.25-1.8-.5-2.753-.5C4.394 10 4 12.32 4 14c0 1.764.827 3.231 2.236 4.287C7.66 19.356 9.69 20 12 20s4.339-.645 5.764-1.713C19.173 17.23 20 15.764 20 14Zm-10 .5c0 1.38-.672 2.5-1.5 2.5S7 15.88 7 14.5 7.672 12 8.5 12s1.5 1.12 1.5 2.5Zm5.5 2.5c.828 0 1.5-1.12 1.5-2.5s-.672-2.5-1.5-2.5-1.5 1.12-1.5 2.5.672 2.5 1.5 2.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGithub;
