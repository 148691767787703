import React from 'react';
import './App.css';

import Header from './components/Header/Header.js'
import ScrollContainer from './components/ScrollContainer/ScrollContainer.js';

function App() {
    return (
        <>
            <Header/>
            <ScrollContainer/>
        </> 
    ) 
};

export default App;
