import React from 'react';
import "./SVG.css"

const SvgSchool = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40px"
    height="40px"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10.958 8.519c.008.159.022.32.042.481a6.226 6.226 0 0 0 4.722-1.347c.235-.019.468-.022.695-.01 1.257.068 2.294.603 2.88 1.642.445.789.703 1.655.703 2.715 0 2.082-.954 4.252-2.35 6.312-1.054 1.555-3.146 2.104-5.088 1.424l-.231-.081a1 1 0 0 0-.662 0l-.231.081c-1.942.68-4.035.131-5.089-1.424C4.953 16.252 4 14.082 4 12a5.38 5.38 0 0 1 .662-2.646c.572-1.056 1.61-1.607 2.88-1.687 1.12-.07 2.357.237 3.417.852ZM13 3.586V3a1 1 0 1 0-2 0v3.31c-1.145-.483-2.392-.715-3.586-.639-1.793.114-3.54.937-4.512 2.73A7.377 7.377 0 0 0 2 12c0 2.662 1.202 5.233 2.694 7.434 1.64 2.419 4.703 3.092 7.306 2.223 2.602.869 5.667.196 7.306-2.223C20.798 17.234 22 14.662 22 12a7.344 7.344 0 0 0-.96-3.698c-.847-1.5-2.237-2.302-3.73-2.566A6.22 6.22 0 0 0 18 2a6.231 6.231 0 0 0-5 1.586Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSchool;
